import React, { useContext, useEffect, useRef, useState } from "react";
import DestinationCard from "./DestinationCard";
import { Box, Pagination, PaginationItem } from "@mui/material";
import { store } from "../../util/store";
import { makeStyles } from "@mui/styles";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "white",
    width: "100%",
    flex: "1 1 0"
  },
  cards: {
    flex: "1",
    overflow: "auto",
  },
  boxShadow: {
    boxShadow: "0px 2px 3px #00000017",
    border: "1px solid #d3d3d36b",
  },
};

const useStyles = makeStyles({
  ul: {
    justifyContent: "space-evenly",
    paddingTop: "10px",
    boxShadow: "0px 5px 7px #000000bd",
    paddingBottom: "10px",
    height: "50px",
  },
  root: {},
});

const DestinationsPanel = (props) => {
  const { markers, map } = props;
  const { searchTerm, typeFilter, countryFilter } = useContext(store);
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const filteredMarkers = markers.filter((marker) => marker.display === true);
  const topAnchorRef = useRef(null);
  const bottomAnchorRef = useRef(null);
  const containerRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [rerender, forceRerender] = useState(0);
  useEffect(() => {
    setCurrentPage(1);
    forceRerender(Math.random());
  }, [searchTerm, typeFilter, countryFilter]);

  const handlePageChange = (event, value) => {
    topAnchorRef && topAnchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    setCurrentPage(value);
  };

  useEffect(() => {}, []);

  const pageRenderer = (item) => (
    <PaginationItem
      {...item}
      sx={
        item.type === "next" || item.type === "previous"
          ? styles.boxShadow
          : undefined
      }
    />
  );

  const paginate = (array, size, pageNumber) => {
    return array.slice((pageNumber - 1) * size, pageNumber * size);
  };

  const cards = paginate(filteredMarkers, 25, currentPage);

  return (
    <div style={styles.root}>
      <div style={styles.cards} ref={containerRef}>
        <div id="topAnchor" ref={topAnchorRef} />
        {!cards ||
          (cards.length === 0 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              No results found
            </Box>
          ))}
        {cards.map((card, index) => (
          <DestinationCard
            destination={card.location}
            googleMarker={card.googleMarker}
            map={map}
            key={index}
          />
        ))}
        {/* when this span is visible in the viewport, it means the user has
        scrolled to the bottom of the results and we can then show the footer */}
        <div id="bottomAnchor" ref={bottomAnchorRef} />
      </div>
      {filteredMarkers.length > 25 && (
        <Pagination
          count={Math.ceil(filteredMarkers.length / 25)}
          page={currentPage}
          onChange={handlePageChange}
          variant="text"
          classes={{ ul: classes.ul, root: classes.root }}
          renderItem={pageRenderer}
        />
      )}
    </div>
  );
};

export default DestinationsPanel;
