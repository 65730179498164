export const terracottaStyle = {
  styles: [
    {
      featureType: "landscape",
      stylers: [
        {
          color: "#f9ddc5",
        },
        {
          lightness: -7,
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          color: "#91b4f2",
        }
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.highway",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
  mapTypeId: 'roadmap',
};

export const defaultStyle = {
  styles: [
    {
      featureType: "landscape",
      stylers: [
        {
          color: "#f9ddc5",
        },
        {
          lightness: -7,
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          color: "#91b4f2",
        }
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.highway",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
  mapTypeId: 'hybrid',
}