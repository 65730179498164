import { Image } from "../shared/Image";

const styles = {
  image: {
    width: "168px",
    marginLeft: { xs: "10px" },
    marginBottom: "-10px",
  },
  text: {
    position: "absolute",
    left: "200px",
    fontSize: {
      md: "18px",
      lg: "25px",
    },
  },
};

export const BannerImage = () => {
  return (
    <>
      <Image
        to="https://moca-ny.org"
        sx={styles.image}
        src="/images/moca_logo.png"
        alt="Moca Logo"
      />
    </>
  );
};
