import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  ListItemText,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { store } from "../util/store";
import { makeStyles } from "@mui/styles";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../config/firebase";

const functions = getFunctions(firebaseApp);
const postGPTPrompt = httpsCallable(functions, "postGPTPrompt");

const steps = [
  "Select a Country",
  "Select a City",
  "Generate Suggested Itinerary",
];

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    backgroundColor: "white",
    marginBottom: "10px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    marginLeft: "15px !important",
    marginRight: "15px !important",
    marginBottom: "5px !important",
  },
  noFocusHighlight: {
    "&:focus": { backgroundColor: "white" },
    display: "flex !important",
  },
  img: {
    marginRight: "15px",
    borderRadius: "100%",
  },
});

export const ItineraryWizard = () => {
  const { countries, destinations: allDestinations } = useContext(store);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [cities, setCities] = useState<any[]>([]);
  const [destinations, setDestinations] = useState([]);

  const handleCountrySelect = (country: string) => {
    setSelectedCountry(country);
    setActiveStep(1);
  };

  const handleCitySelect = (city: string) => {
    setSelectedCity(city);
    setActiveStep(2);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    const citiesMap: { [key: string]: boolean } = {};
    const filteredDestinations = allDestinations.filter((destination: any) => {
      if (destination.country === selectedCountry) {
        citiesMap[destination.city] = true;
        return true;
      }
      return false;
    });
    setDestinations(filteredDestinations as any);
    console.log(filteredDestinations);
    setCities(Object.keys(citiesMap));
  }, [allDestinations, selectedCountry]);

  useEffect(() => {
    const filteredDestinations = allDestinations.filter((destination: any) => {
      if (destination.city === selectedCity) {
        return true;
      }
      return false;
    });
    setDestinations(filteredDestinations as any);
    console.log(filteredDestinations);
  }, [allDestinations, selectedCity]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation="horizontal"
        sx={{ width: "75%", margin: "25px", alignSelf: "center" }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "25px 25%",
          alignItems: "center",
        }}
      >
        {activeStep === 0 && (
          <StepOne
            countries={countries}
            handleCountrySelect={handleCountrySelect}
          />
        )}
        {activeStep === 1 && (
          <StepTwo cities={cities} handleCitySelect={handleCitySelect} />
        )}
        {activeStep === 2 && (
          <StepThree
            selectedCity={selectedCity}
            selectedCountry={selectedCountry}
            destinations={destinations}
          />
        )}
        {activeStep > 0 && (
          <Button
            variant="contained"
            onClick={handleBack}
            sx={{ width: "200px" }}
          >
            Back
          </Button>
        )}
      </Box>
    </Box>
  );
};

const StepOne = (props: any) => {
  const { countries, handleCountrySelect, selectedCountry } = props;
  const classes = useStyles();

  const handleCountryChange = (event: any) => {
    handleCountrySelect(event?.target.value);
  };
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="countryFilterLabel"
          sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          Country
        </InputLabel>
        <Select
          classes={{ select: classes.noFocusHighlight }}
          MenuProps={menuProps as any}
          value={selectedCountry}
          defaultValue=""
          onChange={handleCountryChange}
          label="Country"
          labelId="countryFilterLabel"
          sx={{ width: "400px" }}
        >
          <MenuItem key="" value="">
            <ListItemText primary="No filter" />
          </MenuItem>
          {countries?.map((country: any) => (
            <MenuItem key={country} value={country}>
              <img
                src={`/images/flags/${country
                  .toLowerCase()
                  .replace(/ /g, "-")}.png`}
                alt={`${country}_flag_icon`}
                height="30px"
                className={classes.img}
              />
              <ListItemText primary={country} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

const StepTwo = (props: any) => {
  const { cities, handleCitySelect, selectedCity } = props;
  const classes = useStyles();

  const handleCityChange = (event: any) => {
    handleCitySelect(event?.target.value);
  };

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="cityFilterLabel"
          sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          City
        </InputLabel>
        <Select
          classes={{ select: classes.noFocusHighlight }}
          MenuProps={menuProps as any}
          value={selectedCity}
          defaultValue=""
          onChange={handleCityChange}
          label="City"
          labelId="cityFilterLabel"
          sx={{ width: "400px" }}
        >
          <MenuItem key="" value="">
            <ListItemText primary="No filter" />
          </MenuItem>
          {cities?.map((city: any) => (
            <MenuItem key={city} value={city}>
              <ListItemText primary={city} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

const StepThree = (props: any) => {
  const { destinations, selectedCity, selectedCountry } = props;
  const [result, setResult] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showGenerate, setShowGenerate] = useState<boolean>(false);

  useEffect(() => {
    setShowGenerate(true);
  }, [selectedCity]);

  const handleGenerate = async () => {
    setLoading(true);
    let prompt = `Provide a list of real recommendations to a ceramics enthusiast.
    Do not invent locations. Only respond with a realistic, day-to-day itinerary list for ${selectedCity}, ${selectedCountry}
    that includes the following destinations and other highly recommended landmarks: `;
    for (let i = 0; i < 5 && destinations[i] !== undefined; i++) {
      const destination = destinations[i];
      const string = ` ${destination.name} (${destination.type[0]}) `;
      prompt += string;
      if (i + 1 < 5 && destinations[i + 1] !== undefined) {
        prompt += ",";
      } else {
        prompt += ".";
      }
    }

    await postGPTPrompt({
      prompt,
      systemContext:
        "You are an itinerary generator that will only respond with the requested itinerary.",
    }).then((response) => {
      console.log((response.data as any).content);
      setResult((response.data as any).content);
      setLoading(false);
      setShowGenerate(false);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {showGenerate && !loading && (
        <Button
          variant="contained"
          onClick={handleGenerate}
          sx={{ width: "200px", margin: "15px" }}
        >
          Generate Itinerary
        </Button>
      )}
      {loading && (
        <>
          <CircularProgress
            title="Loading"
            thickness={2.5}
            variant="indeterminate"
            size={100}
          />
          <Typography>
            Please wait while we generate your itinerary...
          </Typography>
        </>
      )}
      {result && (
        <Box>
          {JSON.stringify(result)
            .slice(1, JSON.stringify(result).length - 1)
            .split("\\n")
            .map((item: any) => {
              return <p>{item}</p>;
            })}
        </Box>
      )}
    </Box>
  );
};
