import { createTheme, ThemeProvider } from "@mui/material";
import { BannerContainer } from "./components/banner/BannerContainer";
import { Footer } from "./components/footer/Footer";
import Router from "./Router";
import { StateProvider } from "./util/store";
import CookieNotice from "./components/footer/CookieNotice";
import { useEffect } from "react";

const breakpoints = {
  values: {
    xs: 0,
    sm: 750,
    md: 1040,
    lg: 1400,
    xl: 1636,
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#d3d3d3",
    },
  },
  breakpoints,
  typography: {
    fontFamily: [
      "Work Sans",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

const App = () => {

  useEffect(() => {
    const link1 = document.createElement('link');
    link1.rel = 'preconnect';
    link1.href = 'https://fonts.googleapis.com';

    const link2 = document.createElement('link');
    link2.rel = 'preconnect';
    link2.href = 'https://fonts.gstatic.com';
    link2.crossOrigin = 'true';

    const link3 = document.createElement('link');
    link3.rel = 'stylesheet';
    link3.href = 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;700&display=swap';

    const link4 = document.createElement('link');
    link4.rel = 'stylesheet';
    link4.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';

    document.head.appendChild(link1);
    document.head.appendChild(link2);
    document.head.appendChild(link3);
    document.head.appendChild(link4);

    return () => {
      // Cleanup by removing the links if necessary
      document.head.removeChild(link1);
      document.head.removeChild(link2);
      document.head.removeChild(link3);
      document.head.removeChild(link4);
    };
  }, []);

  return (
    <StateProvider>
      <ThemeProvider theme={theme}>
        <BannerContainer />
        <Router />
        <Footer />
        <CookieNotice />
      </ThemeProvider>
    </StateProvider>
  );
};

export default App;
