import { Box } from "@mui/material";

const styles = {
  icon: {
    width: "40px",
    height: "40px",
  },
};

export const SocialMediaLinks = () => {
  return (
    <Box
      sx={{
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/museumofceramicartny"
      >
        {" "}
        <img
          style={styles.icon}
          src="/images/smicons/fb_icon.png"
          alt="facebook icon"
          height="100%"
        />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/moca_newyork"
      >
        {" "}
        <img
          style={styles.icon}
          src="/images/smicons/IGIcon.png"
          alt="instagram icon"
          height="100%"
        />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.youtube.com/@museumofceramicartNY"
      >
        {" "}
        <img
          style={styles.icon}
          src="/images/smicons/YTIcon.png"
          alt="youtube icon"
          height="100%"
        />
      </a>
    </Box>
  );
};
