import { Route, Routes } from "react-router-dom";
import WorldMap from "./components/map/WorldMap";
import { ItineraryWizard } from "./pages/ItineraryWizard";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<WorldMap />} />
      <Route path="/cwd" element={<WorldMap />} />
      <Route path="/wizard" element={<ItineraryWizard />} />
    </Routes>
  );
};

export default Router;
