import { Box } from "@mui/material";
import { SubscribeButton } from "../shared/SubscribeButton";

const styles = {
  container: {},
  submitContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    border: "1px solid gray",
    backgroundColor: "#313131",
    borderRadius: "0px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#313131",
    },
  },
  text: {
    fontSize: "16px",
  },
};

export const NewsletterEntry = () => {
  return (
    <Box sx={styles.container}>
      <SubscribeButton
        passedStyles={{ button: styles.button, text: styles.text }}
        text="Subscribe"
      />
    </Box>
  );
};
