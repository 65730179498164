import { Box } from "@mui/material";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { isMobile } from "../../util/screenSizeHelpers";
import { store } from "../../util/store";
import { ContactInfo } from "./ContactInfo";
import { FooterNavLinks } from "./FooterNavLinks";
import { NewsletterEntry } from "./NewsletterEntry";
import { SocialMediaLinks } from "./SocialMediaLinks";

const styles = {
  container: {
    height: {
      sm: "250px",
    },
    borderTop: "1px solid black",
    backgroundColor: "#313131",
    color: "white",
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
    flex: "none",
    flexShrink: {
      xs: "1",
      sm: "0",
    },
  },
  gridItem: {
    width: { xs: "100%", sm: "33.33%" },
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: {
      xs: "15px",
      sm: "0px",
    },
  },
  firstBox: {
    flexDirection: "column",
    width: "140px",
    height: {
      xs: "150px",
      sm: "125px",
    },
    justifyContent: "space-evenly",
    display: "flex",
  },
  offScreen: {
    position: "absolute",
    bottom: "-250px",
    width: "100%",
  },
};
export const Footer = () => {
  const location = useLocation();
  const { screenSize } = useContext(store);
  const offScreenPages = ["/cwd", "/", "/cwd/submit", "/cwd/criteria"];
  const offscreen = offScreenPages.includes(location.pathname);

  if (offscreen && isMobile(screenSize)) {
    return null;
  }
  return (
    <Box>
      <Box
        sx={
          offscreen
            ? { ...styles.container, ...styles.offScreen }
            : styles.container
        }
      >
        <Box sx={styles.gridItem}>
          <Box sx={styles.firstBox}>
            <SocialMediaLinks />
            <NewsletterEntry />
          </Box>
        </Box>
        <Box sx={styles.gridItem}>
          <a href="https://moca-ny.org">
            <img
              src="./images/white_logo.png"
              alt="logo"
              style={{ width: "150px" }}
            ></img>
          </a>
          <ContactInfo />
          <div
            style={{
              backgroundColor: "#313131",
              color: "white",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            Copyright © 2024 Museum of Ceramic Art - New York. All rights
            reserved.
          </div>
        </Box>
        <Box sx={styles.gridItem}>
          <FooterNavLinks />
        </Box>
      </Box>
    </Box>
  );
};
